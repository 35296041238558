<template>
  <div>
    <div class="row maindashboard">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="cardcontents">
          <div class="card-header">
            안전준비태세(SAFCON) 경보단계
          </div>
          <div class="card-body" style="height: 240px">
            <div class="row">
              <div class="col-4 safcon-left" style="text-align: center !important;">
                <img :src="safconImg" width="90px" :class="safconText != '무사고' ? 'safconimgOn': ''" @click="clickSafcon"/><br>
                <div :class="safconTextClass">{{ safconText }}</div>
              </div>
              <div class="col-8 safcon-right" style="text-align: center !important;">
                <div class="row">
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass1">무사고</div>
                  </div>
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass2">주의</div>
                  </div>
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass3">경계</div>
                  </div>
                  <div class="col-3" style="text-align: center !important;">
                    <div :class="safconTextClass4">심각</div>
                  </div>
                  <div class="col-12 safconDate" style="text-align: center !important;">
                    {{ safconData.startDt }} ~ {{ safconData.endDt }}
                  </div>
                  <div class="col-12 safconTeam" style="text-align: center !important;">
                    <div class="row">
                      <div class="col-12">안전사고 <span class="safconbox">{{ safconData.total }}</span>건</div>
                      <div class="col-6">생산1팀 <span class="safconbox">{{ safconData.team1 }}</span>건</div>
                      <div class="col-6">생산2팀 <span class="safconbox">{{ safconData.team2 }}</span>건</div>
                      <div class="col-6">생산3팀 <span class="safconbox">{{ safconData.team3 }}</span>건</div>
                      <div class="col-6">기&nbsp;&nbsp;&nbsp;&nbsp;타 <span class="safconbox">{{ safconData.etc }}</span>건</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
        <div class="cardcontents">
          <div class="card-header2">
            <div class="card-more-add" @click="goPage('/sop/iim/status/accidentStatus')"><span class="material-icons">add</span><q-tooltip>더보기</q-tooltip></div>
            <q-chip
              v-for="(acc, idx) in accidentType"
              outline square
              :key="idx"
              :removable="false"
              :color="acc.selected ? 'orange' : 'grey-6'"
              text-color="white"
              :icon-selected="acc.selected ? 'check' : ''"
              :selected.sync="acc.selected"
              :label="acc.name"
              class="mainAccTypeChip"
              v-model="acc.check"
              @update:selected="state => selectedAccGraph(state, acc, idx)"
            ></q-chip>
            <div class="card-more main-header-input main-header-input23">
              <c-datepicker
                :appendToBody="false"
                type="year"
                default="today"
                name="accYear"
                v-model="accYear"
                @datachange="getAccGraph"
              />
            </div>
          </div>
          <div class="card-body" style="height: 240px">
            <apexchart 
              v-show="selectedAccCode == 'type1'"
              ref="mainAccChart1" 
              height="220" 
              type="bar"
              :options="mainAccChart1.chartOptions" 
              :series="mainAccChart1.series"></apexchart>
            <apexchart 
              v-show="selectedAccCode == 'type102'"
              ref="mainAccChart102" 
              height="220" 
              type="bar"
              :options="mainAccChart102.chartOptions" 
              :series="mainAccChart102.series"></apexchart>
            <apexchart 
              v-show="selectedAccCode == 'type2'"
              ref="mainAccChart2" 
              height="220" 
              type="bar"
              :options="mainAccChart2.chartOptions" 
              :series="mainAccChart2.series"></apexchart>
            <apexchart 
              v-show="selectedAccCode == 'type3'"
              ref="mainAccChart3" 
              height="220" 
              type="bar"
              :options="mainAccChart3.chartOptions" 
              :series="mainAccChart3.series"></apexchart>
            <apexchart 
              v-show="selectedAccCode == 'type32'"
              ref="mainAccChart32" 
              height="220" 
              type="bar"
              :options="mainAccChart32.chartOptions" 
              :series="mainAccChart32.series"></apexchart>
            <apexchart 
              v-show="selectedAccCode == 'type4'"
              ref="mainAccChart4" 
              height="220" 
              type="bar"
              :options="mainAccChart4.chartOptions" 
              :series="mainAccChart4.series"></apexchart>
            <apexchart 
              v-show="selectedAccCode == 'type5'"
              ref="mainAccChart5" 
              height="220" 
              type="line"
              :options="mainAccChart5.chartOptions" 
              :series="mainAccChart5.series"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <div class="cardcontents">
          <div class="card-header">
            최근 안전사고
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCdAcc"
                :isFirstValue="true"
                label=""
                v-model="plantCdAcc"
                @datachange="getNearmiss"/>
            </div>
          </div>
          <div class="card-body">
            <c-table
              ref="gridAcc"
              tableId="gridAcc"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="220px"
              :columns="gridAcc.columns"
              :data="gridAcc.data"
              @linkClick="linkClickAcc"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
        <div class="cardcontents">
          <div class="card-header">
            관계법령 의무사항 사업주 이행현황
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCdLaw"
                :isFirstValue="true"
                label=""
                v-model="plantCdLaw"
                @datachange="getLawCheck"/>
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                :appendToBody="false"
                type="year"
                default="today"
                name="lawYear"
                v-model="lawYear"
                @datachange="getLawCheck"
              />
            </div>
          </div>
          <div class="card-header2">
            <q-chip
              v-for="(law, idx) in lawType"
              outline square
              :key="idx"
              :removable="false"
              :color="law.selected ? 'orange' : 'grey-6'"
              text-color="white"
              :icon-selected="law.selected ? 'check' : ''"
              :selected.sync="law.selected"
              :label="law.name"
              class="mainAccTypeChip"
              v-model="law.check"
              @update:selected="state => selectedLaw(state, law, idx)"
            ></q-chip>
          </div>
          <div class="card-body" style="height: 464px">
            <c-table
              ref="gridLaw"
              :columns="gridLaw.columns"
              :data="gridLaw.data"
              gridHeight="450px"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
            >
              <template v-slot:customArea="{ props, col }">
                <template v-if="col.stype === 'check'">
                  <q-icon v-if="props.row[col.name] == 'Y'" name="check"></q-icon>
                </template>
                <template v-if="col.name === 'status'">
                  <div :class="getLawStatusIcon(props.row)" @click="openLawPop(props.row)"></div>
                </template>
              </template>
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
        <div ref="main-dashborad" class="cardcontents directorMainLayer">
          <div class="card-header">
            작업허가서 현황
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 0px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              infinite
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              @input="changeCarousel"
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="mapWorkIcons">
                    <q-expansion-item
                      dense
                      class="shadow-1 overflow-hidden"
                      style="border-radius: 10px"
                      icon="explore"
                      label="범례"
                      header-class="bg-primary text-white"
                      expand-icon-class="text-white"
                      expand-icon="expand_less"
                      expanded-icon="expand_more"
                      default-opened
                    >
                      <q-card>
                        <q-card-section>
                          <ul>
                            <li v-for="(icon, iconIdx) in swpIconItems"
                              :key="iconIdx"
                            >
                              <q-icon 
                                :name="icon.icon" 
                                class="text-red"
                                size="16px" />
                              {{ icon.name }}
                            </li>
                          </ul>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </div>
                  <!-- 작업허가서 -->
                  <template v-if="mapObj.activeTask===1">
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :class="item.idx === mapObj.activeMarked ? 'selected' : ''"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="(item.locationXcoordinate * mapObj.ratio - 15)  * mapRate"
                      :y="(item.locationYcoordinate * mapObj.ratio - 15)  * mapRate"
                      :w="30 " :h="30"
                      :grid="[5, 5]"
                      @activated="onMarkedInfo(item)"
                    >
                      <c-map-marker :isSelected="item.idx === mapObj.activeMarked" />
                      <q-menu 
                        :ref="'markedproxy' + item.idx" 
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <q-card 
                          v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                          :key="idx"
                          flat bordered 
                          @click="openWorkPermitPop(workPermit)"
                          :class="['main-work-permit-card main-work-permit-card-cursor', setStepClass(workPermit.swpStepCd)]">
                          <q-item class="main-work-permit-card-section main-work-permit-card-item">
                            <q-item-section avatar>
                              <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label>
                                <q-icon 
                                  v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCd)"
                                  :key="iconIdx"
                                  :name="icon" 
                                  class="text-red"
                                  size="25px" />
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="main-work-permit-card-section2">
                            <b>{{workPermit.workSummary}}</b>
                          </q-card-section>
                          <q-card-section class="main-work-permit-card-section1">
                            {{workPermit.workTime}}
                          </q-card-section>
                        </q-card>
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="312"
              description="사업장에 등록된 지도가 없습니다."></el-empty>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: 'main-dashboard-director',

  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      accYear: '',
      plantCdLaw: null,
      plantCdAcc: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      accident: {
        startYmd: '',
        endYmd: '',
      },
      accidentType: [
        {
          code: 'type1',
          name: '형태',
          check: '',
          selected: true,
        },
        {
          code: 'type102',
          name: '유형',
          check: '',
          selected: false,
        },
        {
          code: 'type2',
          name: '상해',
          check: '',
          selected: false,
        },
        {
          code: 'type3',
          name: '팀별',
          check: '',
          selected: false,
        },
        {
          code: 'type32',
          name: '반별',
          check: '',
          selected: false,
        },
        {
          code: 'type4',
          name: '요일',
          check: '',
          selected: false,
        },
        {
          code: 'type5',
          name: '시간',
          check: '',
          selected: false,
        },
      ],
      lawType: [
        {
          code: 'SHTC000001',
          name: '환경',
          check: '',
          selected: true,
        },
        {
          code: 'SHTC000002',
          name: '안전보건',
          check: '',
          selected: false,
        },
        {
          code: 'SHTC000003',
          name: '소방',
          check: '',
          selected: false,
        },
      ],
      gridAcc: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: "upOccurrenceDeptName",
            field: "upOccurrenceDeptName",
            style: 'width:90px',
            label: "팀",
            align: "center",
            sortable: false,
          },
          {
            name: "occurrenceDeptName",
            field: "occurrenceDeptName",
            style: 'width:110px',
            label: "반",
            align: "center",
            sortable: false,
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            label: "일시",
            align: "center",
            sortable: false,
          },
          {
            name: "accidentTypeName",
            field: "accidentTypeName",
            style: 'width:90px',
            label: "재해유형",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      safconData: {
        team1: 0,
        team2: 0,
        team3: 0,
        etc: 0,
        total: 0,
        imports: 0,
        startDt: '',
        endDt: '',
      },
      fab: true,
      mapObj: {
        activeTask: 1,
        activeMarked: 0,
        selectedWorkPermit: {
          workPermits: [],
        },
        envUrl: '',
        markInfoUrl: '',
        processUrl: '',
        url: '',
        interval: {
          obj: null,
          seconds: 3,
          isPause: false,
        },
        taskParam: {
          workPermitPeriod: [],
        },
        location: {
          mdmLocationId: '',
          locationName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        maps: [],
        orignMaps: [],
        slide: 0,
        height: 575,
        width: 800,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
        loading: false,
      },
      workPermitUrl: '',
      safconUrl: '',
      safconImg: '',
      safconText: '',
      safconTextClass: '',
      safconTextClass1: 'safcon-circle',
      safconTextClass2: 'safcon-circle',
      safconTextClass3: 'safcon-circle',
      safconTextClass4: 'safcon-circle',
      AccGraph1Url: '',
      AccGraph2Url: '',
      AccGraph3Url: '',
      AccGraph32Url: '',
      AccGraph4Url: '',
      AccGraph5Url: '',
      lawCheckUrl: '',
      lawYear: '',
      gridLaw: {
        columns: [
          {
            name: 'targetName',
            field: 'targetName',
            //목표
            label: 'LBL0010017',
            align: 'left',
            sortable: false,
          },
          {
            name: 'month1',
            field: 'month1',
            stype: 'check',
            //1월
            label: 'LBL0010023',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month2',
            field: 'month2',
            stype: 'check',
            //2월
            label: 'LBL0010024',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month3',
            field: 'month3',
            stype: 'check',
            //3월
            label: 'LBL0010025',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month4',
            field: 'month4',
            stype: 'check',
            //4월
            label: 'LBL0010026',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month5',
            field: 'month5',
            stype: 'check',
            //5월
            label: 'LBL0010027',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month6',
            field: 'month6',
            stype: 'check',
            //6월
            label: 'LBL0010028',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month7',
            field: 'month7',
            stype: 'check',
            //7월
            label: 'LBL0010029',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month8',
            field: 'month8',
            stype: 'check',
            //8월
            label: 'LBL0010030',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month9',
            field: 'month9',
            stype: 'check',
            //9월
            label: 'LBL0010031',
            align: 'center',
            style: 'width:30px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month10',
            field: 'month10',
            stype: 'check',
            //10월
            label: 'LBL0010032',
            align: 'center',
            style: 'width:36px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month11',
            field: 'month11',
            stype: 'check',
            //11월
            label: 'LBL0010033',
            align: 'center',
            style: 'width:36px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'month12',
            field: 'month12',
            stype: 'check',
            //12월
            label: 'LBL0010034',
            align: 'center',
            style: 'width:36px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'status',
            field: 'status',
            label: '여부',
            align: 'center',
            style: 'width: 36px',
            sortable: false,
            type: 'custom',
          },
        ],
        data: [],
      },
      // 발생형태
      mainAccChart1: { 
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            },
          },
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      // 재해유형
      mainAccChart102: { 
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            },
          },
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart2: { 
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            },
          },
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart3: { 
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            },
          },
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart32: { 
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            },
          },
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart4: { 
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false
            },
          },
          title: {
            text: ''
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + " 건"
              }
            }
          },
          legend: {
            show: false
          }
        },
        series: [
          
        ],
        //  21, 12, 12, 15, 21, 12],
        chartWidth: '100%',
      },
      mainAccChart5: { 
        chartOptions: {
          title: {
            text: ''
          },
          legend: {
            show: false
          },
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: ['00시', '01시', '02시', '03시', '04시', '05시', '06시', '07시', '08시','09시','10시','11시','12시',
            '13시','14시','15시','16시','17시','18시','19시','20시','21시','22시','23시'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '시간',
            //24개
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ],
        chartWidth: '100%',
      },
      selectedAccCode: 'type1',
      selectedAccName: '유형',
      selectedLawCode: 'SHTC000001',
      selectedLawName: '환경',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  activated() {
    this.getMapData();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    swpIconItems() {
      return [
        {
          icon: 'work',
          name: '일반',
        },
        {
          icon: 'local_fire_department',
          name: '화기',
        },
        {
          icon: 'dangerous',
          name: '밀폐',
        },
        {
          icon: 'flash_off',
          name: '전기차단',
        },
        {
          icon: 'hardware',
          name: '굴착',
        },
        {
          icon: 'stairs',
          name: '고소',
        },
        {
          icon: 'public',
          name: '환경조치',
        },
      ];
    }, 
    mapRate() {
      return 1 // 0.825
    },
  },
  methods: {
    init() {
      this.workPermitUrl = selectConfig.main.workPermitStatus.url;
      // 안전준비태세
      this.safconUrl = selectConfig.main.safcon.url;
      // 지도
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url
      
      this.AccGraph1Url = selectConfig.sop.iim.accident.status.kind1.url;
      this.AccGraph102Url = selectConfig.sop.iim.accident.status.kind102.url;
      this.AccGraph2Url = selectConfig.sop.iim.accident.status.kind2.url;
      this.AccGraph3Url = selectConfig.sop.iim.accident.status.dept1.url;
      this.AccGraph32Url = selectConfig.sop.iim.accident.status.dept2.url;
      this.AccGraph4Url = selectConfig.sop.iim.accident.status.season.url;
      this.AccGraph5Url = selectConfig.sop.iim.accident.status.hour.url;
      this.lawCheckUrl = selectConfig.main.lawcheck.url;

      this.selectedAccCode = 'type1';
      this.selectedAccName = '형태';
      this.selectedLawCode = 'SHTC000001';
      this.selectedLawName = '환경';

      this.setSize(true);

      this.getAccGraph1();
      
      this.getMapPriodParam();
      this.getMapData();
      this.getNearmiss();
      this.getSafcon();
      this.getLawCheck();

    },
    setSize(isFirst) {
      // this.mapObj.height = this.$refs['main-dashborad'].offsetHeight
      this.mapObj.width = this.$refs['main-dashborad'].offsetWidth
      if (isFirst instanceof  Event || isFirst === false) {
        this.setMapSize(this.mapObj.slide)
      }
    },
    changeCarousel() {
      this.setSize(false)
    },
    selectedAccGraph(state, acc, idx) {
      this.selectedAccCode = acc.code;
      this.selectedAccName = acc.name;
      if (state) {
        this.accidentType.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.accidentType[idx], 'selected', true)
      }
      switch(acc.code) {
        case 'type1':
          this.getAccGraph1();
          break;
        case 'type102':
          this.getAccGraph102();
          break;
        case 'type2':
          this.getAccGraph2();
          break;
        case 'type3':
          this.getAccGraph3();
          break;
        case 'type32':
          this.getAccGraph32();
          break;
        case 'type4':
          this.getAccGraph4();
          break;
        case 'type5':
          this.getAccGraph5();
          break;
      }
    },
    selectedLaw(state, law, idx) {
      this.selectedLawCode = law.code;
      this.selectedLawName = law.name;
      if (state) {
        this.lawType.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.lawType[idx], 'selected', true)
      }
      this.getLawCheck();
    },
    getSafcon() {
      this.$http.url = this.safconUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.safconData = _result.data
        if (this.safconData.imports > 0) {
          this.safconText = '심각';
          this.safconTextClass = 'safcon-left-text text-red';
          this.safconImg = require('@/assets/images/safcon/level04.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle bg-red'
        } else if (this.safconData.total == 0) {
          this.safconText = '무사고';
          this.safconTextClass = 'safcon-left-text text-green';
          this.safconImg = require('@/assets/images/safcon/level01.png');
          this.safconTextClass1 = 'safcon-circle bg-green'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle'
        } else if (this.safconData.total <= 2) {
          this.safconText = '주의';
          this.safconTextClass = 'safcon-left-text text-yellow';
          this.safconImg = require('@/assets/images/safcon/level02.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle bg-yellow'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle'
        } else if (this.safconData.total == 3) {
          this.safconText = '경계';
          this.safconTextClass = 'safcon-left-text text-orange';
          this.safconImg = require('@/assets/images/safcon/level03.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle bg-orange'
          this.safconTextClass4 = 'safcon-circle'
        } else if (this.safconData.total >= 4) {
          this.safconText = '심각';
          this.safconTextClass = 'safcon-left-text text-red';
          this.safconImg = require('@/assets/images/safcon/level04.png');
          this.safconTextClass1 = 'safcon-circle'
          this.safconTextClass2 = 'safcon-circle'
          this.safconTextClass3 = 'safcon-circle'
          this.safconTextClass4 = 'safcon-circle bg-red'
        }
      },);
    },
    setLangLabel() {
      let thisVue = this;
      this.imprChart.series = [
      {
          id: 'actioning',
          name: this.$comm.getLangLabel('LBL0000944'), // 조치중
          data: [],
        },
        {
          id: 'complete',
          name: '개선완료 + 종료',
          data: [],
        },
        {
          id: 'overAction',
          name: this.$comm.getLangLabel('LBL0000934'), // 지연 건수
          data: [],
        },
      ]
      this.imprChart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
        this.$set(this.mapObj.taskParam, 'assessRiskPeriod', [_result.data[1].start, _result.data[1].end])
        this.$set(this.mapObj.taskParam, 'accidentPeriod', [_result.data[3].start, _result.data[3].end])
      },);
    },
    
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    getMapData() {
      // clearInterval(this.interval);
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          /**
           * 지도는 1개
           */
          this.$_.forEach(_result.data, item => {
            this.mapObj.endIdx = 1;
            if (item.otherMaps && item.otherMaps.length > 0) {
              this.$_.forEach(item.otherMaps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
          })

          this.mapObj.maps = this.$_.cloneDeep(_result.data)
          this.setMapSize(0)
        }
      },);
    },
    setMapSize(index) {
      if (this.mapObj.maps && this.mapObj.maps.length > 0) {
        this.mapObj.orignRatio = this.mapObj.maps[index].ratio;
        
        this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[index].ratio);
        
        // this.mapObj.height = 575 // 높이는 resize에서, 너비를 고정
        let imageWidth = this.mapObj.orignRatio * 575;
        let resizeRatio = Math.round((this.mapObj.width / imageWidth) * 100) / 100 // Math.round((this.mapObj.height / (575)) * 100) / 100;
        let _height = (this.mapObj.width / this.mapObj.orignRatio) * this.mapRate
        this.$refs['carousel'].style.setProperty("--map-width", String(this.mapObj.width  * this.mapRate) + 'px');
        this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
        this.mapObj.ratio = resizeRatio
      }
    },
    onMarkedInfo(markedInfo) {
      this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.resultTypes);
      this.$set(this.mapObj, 'activeMarked', markedInfo.idx)
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'work';
          break;
        case 'SSWT000010': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 전기차단
          icon = 'flash_off';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 환경조치
          icon = 'public';
          break;
      }
      return icon;
    },
    openWorkPermitPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: 'SPT0000001',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePermitPopup;
    },
    closePermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    /* eslint-disable no-unused-vars */
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('work');
              break;
            case 'SSWT000010': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 전기차단
              icons.push('flash_off');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 환경조치
              icons.push('public');
              break;
          }
        })
      }
      return icons;
    },
    getLawCheck() {
      this.$http.url = this.lawCheckUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdLaw,
        year: this.lawYear,
        safetyHealthyTypeCd: this.selectedLawCode,
      };
      this.$http.request((_result) => {
        this.gridLaw.data = _result.data
      },);
    },
    getNearmiss() {
      this.$http.url = selectConfig.sop.iim.accident.process.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        mainFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.gridAcc.data = _result.data
      },);
    },
    linkClickAcc(row) {
      this.popupOptions.title = 'LBL0000946'; // 아차사고 상세
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
        stepCd: row ? row.accidentStatusCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentDetail.vue'}`);
      this.popupOptions.width = "90%"
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopupAcc;
    },
    closePopupAcc(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.popupOptions.isFull = false;
    },
    clickSafcon() {
      if (this.safconText != '무사고') {
        this.popupOptions.title = '주간 공정사고 검색';
        this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentMainPop.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeclickSafcon;
      }
    },
    closeclickSafcon() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getAccGraph() {
      switch(this.selectedAccCode) {
        case 'type1':
          this.getAccGraph1();
          break;
        case 'type102':
          this.getAccGraph102();
          break;
        case 'type2':
          this.getAccGraph2();
          break;
        case 'type3':
          this.getAccGraph3();
          break;
        case 'type32':
          this.getAccGraph32();
          break;
        case 'type4':
          this.getAccGraph4();
          break;
        case 'type5':
          this.getAccGraph5();
          break;
      }
    },
    getAccGraph1() {
      this.$http.url = this.AccGraph1Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.mainAccChart1.chartOptions.xaxis.categories = this.$_.map(_result.data, 'accidentTypeName');
        this.mainAccChart1.series = [{data: this.$_.map(_result.data, 'typeCount'), name: '재해 건 수'}];
        
        this.setChart(this.mainAccChart1, 'mainAccChart1');
      },);
    },
    getAccGraph102() {
      this.$http.url = this.AccGraph102Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.mainAccChart102.chartOptions.xaxis.categories = this.$_.map(_result.data, 'accidentTypeName');
        this.mainAccChart102.series = [{data: this.$_.map(_result.data, 'typeCount'), name: '재해 건 수'}];
        
        this.setChart(this.mainAccChart102, 'mainAccChart102');
      },);
    },
    getAccGraph2() {
      this.$http.url = this.AccGraph2Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.mainAccChart2.chartOptions.xaxis.categories = this.$_.map(_result.data, 'woundName');
        this.mainAccChart2.series = [{data: this.$_.map(_result.data, 'woundCount'), name: '재해 건 수'}];
        
        this.setChart(this.mainAccChart2, 'mainAccChart2');
      },);
    },
    getAccGraph3() {
      this.$http.url = this.AccGraph3Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.mainAccChart3.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurrenceDeptName');
        this.mainAccChart3.series = [{data: this.$_.map(_result.data, 'deptCount'), name: '재해 건 수'}];
        
        this.setChart(this.mainAccChart3, 'mainAccChart3');
      },);
    },
    getAccGraph32() {
      this.$http.url = this.AccGraph32Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.mainAccChart32.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurrenceDeptName');
        this.mainAccChart32.series = [{data: this.$_.map(_result.data, 'deptCount'), name: '재해 건 수'}];
        
        this.setChart(this.mainAccChart32, 'mainAccChart32');
      },);
    },
    getAccGraph4() {
      this.$http.url = this.AccGraph4Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.mainAccChart4.chartOptions.xaxis.categories = this.$_.map(_result.data, 'occurDay');
        this.mainAccChart4.series = [{data: this.$_.map(_result.data, 'dayCount'), name: '재해 건 수'}];
        
        this.setChart(this.mainAccChart4, 'mainAccChart4');
      },);
    },
    getAccGraph5() {
      this.mainAccChart5.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      this.$http.url = this.AccGraph5Url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: this.accYear + '-01-01',
        endYmd: this.accYear + '-12-31',
      };
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = this.mainAccChart5.chartOptions.xaxis.categories.indexOf(_item.occurTime)

          this.mainAccChart5.series[0].data[idx] = _item.timeCount
        })
        this.setChart(this.mainAccChart5, 'mainAccChart5');
      },);
    },
    setChart(_chart, _chartName) {
      _chart.chartWidth = '90%';
      this.$refs[_chartName].refresh();
    },
    getLawStatusIcon(_row) {
      let iconColor = '';
      switch(_row.status) {
        case 'SHS0000001':
          iconColor = 'bg-red lawcheckIcon';
          break;
        case 'SHS0000005':
          iconColor = 'bg-yellow lawcheckIcon';
          break;
        case 'SHS0000010':
          iconColor = 'bg-green lawcheckIcon';
          break;
      }
      return iconColor;
    },
    openLawPop(row) {
      this.popupOptions.title = '관계법령 의무사항 사업주 이행현황 상세';  //ESF 목표 및 추진계획/실적 상세
      this.popupOptions.param = {
        mainSafetyHealthyTargetId: row ? row.mainSafetyHealthyTargetId : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/main/mainSafetyHealthyTargetDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeLawPopup;
    },
    closeLawPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.no-padding
  padding: 0px 0px 6px 0px !important
.maindashboard
  .fullscreen-btn
    .q-btn__wrapper
      min-width: 10px !important
      min-height: 10px !important
.maindashboard .cardcontents
  box-shadow: 0 !important
  
  border-radius: 10px !important
  margin-top: 6px
  margin-bottom: 6px
  margin-right: 6px
  .card-body
    padding: 10px !important
    padding-top: 0px !important
    border-radius: 0 0 10px 10px !important
    background: #fff
    .q-table__top
      padding: 0px !important
  .card-header:before
    font-family: "Material Icons"
    content: "\e876"
  .card-header
    border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    background: #fff
    color: #0072c6
    display: inline-block
    padding-top: 8px
    width: 100%
    .card-more
      float: right !important
      cursor: pointer
      color: #d2d2d2
    .card-more-add
      float: right !important
      cursor: pointer
      color: #d2d2d2
      padding-right: 10px
    .card-more:hover
      color: #1ab394
    .inputClass
      background: #fff !important
  .card-header2
    border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    background: #fff
    color: #0072c6
    display: inline-block
    padding-top: 8px
    width: 100%
    .card-more
      float: right !important
      cursor: pointer
      color: #d2d2d2
    .card-more-add
      float: right !important
      cursor: pointer
      color: #d2d2d2
      padding-right: 10px
    .card-more-add:hover
      color: #1ab394
    .card-more:hover
      color: #1ab394
    .inputClass
      background: #fff !important

.main-header-input
  margin-top: -5px
  padding-right: 5px
.main-header-input2
  margin-top: -5px
  padding-right: 5px
  width: 240px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.main-header-input23
  margin-top: -5px
  padding-right: 5px
  width: 120px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

.directorMainLayer
  .workPermit-carousel
    border-radius: 10px
    // height: var(--map-height) !important
    height: var(--map-height) !important
    .q-carousel__slide
      // width: calc(var(--map-ratio) * var(--map-height)) !important
      width: var(--map-width) !important
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    .q-carousel__slides-container
      .q-panel.scroll
        overflow: hidden !important
  

.directorMainLayer
  .caption-work2
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #fff
    padding: 10px
  .caption-work
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

.directorMainLayer
  .mainmapstart
    float: right
    margin-right: 0px
    margin-top: -5px

.directorMainLayer
  .mainMarkImage
    padding: 0px !important
    text-align: center
    font-size: 20px
    height: 30px !important
    position: absolute
    padding: 16px
    color: #C10015
    z-index: 3 !important
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important

.directorMainLayer
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out

.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important
  
.maindashboard thead tr th 
  font-weight:400 !important
.maindashboard .mx-input-wrapper
  input 
    color: #0072c6 !important
    background: white !important
text.apexcharts-xaxis-annotation-label
  fill: black !important
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.my-class {
  /* background-color: #9E9E9E; */
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
  color: black;
  cursor: pointer;
  /* z-index: 1 !important; */
}
.my-class div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
}
.chip-task {
  margin-left: 8px !important;
}
.app-main__inner .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.app-main__inner .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
  color: #fff !important;
}
.app-main__inner .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
  font-size: 0.9em !important;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: rgba(0, 143, 251, 0.85) !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
/* rgba(220, 221, 221, 0.5) !important; */
.mapWorkIcons {
  position: absolute;
  /* background-color: lightgrey;   */
  /* border-radius: 10px; */
  right: 0px;
  bottom: 0px;
  padding: 5px 10px;
  font-weight: 700;
  line-height: 20px;
  z-index: 9999;
  font-size: 11px;
}
.mapWorkIcons .q-item {
  padding: 0px 10px;
}
.mapWorkIcons ul {
  list-style-type: none;
  margin-bottom: 0px !important;
}
.uninjury-area {
  padding-top: 10px;
  font-weight: 700;
  font-size: 1.1em;
  width: 100%;
}
.uninjury-title {
  list-style:none;
  float:left;
  width: 40%;
  line-height: 2em;
  text-align: center;
}
.uninjury-data {
  list-style:none;
  float:left;
  width: 60%;
  line-height: 2em;
}
.uninjury-num {
  width: 90px;
  display: inline-block;
  text-align: right;
  background-color: #686666;
  color:yellow !important;
  height: 26px;
  padding-right: 10px;
  line-height: 26px !important;
}
.uninjury-date {
  width: 120px;
  display: inline-block;
  text-align: center;
  background-color: #686666;
  color: #fff;
  height: 26px;
  line-height: 26px !important;
}

.safcon-left {
  padding-top: 20px !important;
}
.safcon-left-text {
  padding-top: 20px;
  font-size: 2em;
  font-weight: 600;
}
.safcon-right {
  margin-top: 0px;
}
.safcon-circle {
  width: 48px;
  height: 48px;
  background-color: rgb(202, 200, 200);
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  padding-top:10px;
  color: #fff;
}
.safconDate {
  margin-top: 10px;
  padding-top:2px;
  font-size: 1.0em;
  font-weight: 600;
  border:1px solid #cccdcf;
  border-radius: 10px;
  margin-bottom: 15px;
}
.safconTeam {
  border-radius: 10px;
  background-color: rgb(23, 112, 23);
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 0.95em;
  color: #fff;
}
.safconTeam .col-12 {
  font-size: 1.05em;
  font-weight: 600;
}
.safconbox {
  padding:0px 6px;
  background-color: #161d31;
  font-weight: 600;
  text-align: center;
  color: red;
}
.safconimgOn {
  cursor: pointer;
}
.main-work-permit-card-section2 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  cursor: pointer;
}
.main-work-permit-card-section1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.mainAccTypeChip {
  border: 0px !important;
}
.card-header2 .mainAccTypeChip:first-child {
  margin-left: 6px !important;
}
.mainAccTypeChip:focus {
  box-shadow: none !important;
}
.lawcheckIcon {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 50%;
  margin-top: 3px;
  cursor: pointer;
}
</style>